import { Component } from '@angular/core';
import { BrowserService } from 'src/app/shared/services/browser.service';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss'],
})

export class ExperienceComponent {

  constructor(private browserService: BrowserService) {
    this.browserService.init('Experience', 'Rohith have worked with many conglomerates, in Australia and India. Here are a few.', '');
  }

  companies = [
    { name: "NAB (Current)", role: "Technical Lead", image: 'assets/images/company/nab.png' },
    { name: "Kaluza", role: "Senior Technical Lead", image: 'assets/images/company/kaluza.png' },
    { name: "Cevo Australia", role: "Technical Lead", image: 'assets/images/company/cevo.jpeg' },
    { name: "Ippon Australia", role: "Lead Developer", image: 'assets/images/company/ippon.png' },
    { name: "Traffic Technologies", role: "Lead Developer", image: 'assets/images/company/traffic.png' },
    { name: "Momenton", role: "Senior Developer", image: 'assets/images/company/momenton.png' },
    { name: "REA Group", role: "FullStack Developer", image: 'assets/images/company/rea.png' },
    { name: "DHHS Victoria", role: "FullStack Developer", image: 'assets/images/company/dhhs.png' },
    { name: "CitySoft", role: "Senior Developer", image: 'assets/images/company/citysoft.png' },
    { name: "TradeCreative Media", role: "Web Developer", image: 'assets/images/company/tradecreative.png' },
    { name: "Tata Consultancy Services", role: "Systems Engineer", image: 'assets/images/company/tcs.png' },
  ]
}
