import { Component } from '@angular/core';
import { BrowserService } from 'src/app/shared/services/browser.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})

export class ProjectComponent {
  constructor(private browserService: BrowserService) {
    this.browserService.init('Projects', 'Here are a few projects that Rohith have worked with', '');
  }

  projects = [
    { name: 'GDD', url: 'https://gdd.rohithpoyyeri.com/', image: 'assets/images/projects/gdd.png', description: 'Gif Driven Development' },
    { name: 'GrocStock', url: 'https://grocstock.com.au/', image: 'assets/images/projects/grocstock.svg', description: 'GrocStock, Organise anything, anywhere.' },
    { name: 'GrocStock Blogs', url: 'https://blogs.grocstock.com.au/', image: 'assets/images/projects/grocstock.svg', description: 'GrocStock, Organise anything, anywhere.' },
  ]
}
